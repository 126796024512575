import React from "react";
import GlobalStyles from 'styles/GlobalStyles';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ServiceLandingPage from "demos/ServiceLandingPage.js";



export default function App() {
  return (
    <>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path="/" element={<ServiceLandingPage />} />
        </Routes>
      </Router>
    </>
  );
}
