import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-6.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";

const HeaderContainer = tw.div`w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const PlansContainer = tw.div`flex justify-center flex-col md:flex-row items-start md:items-start relative`;

const Plan = styled.div`
  ${tw`w-full max-w-screen-lg mt-8 md:mr-12 md:last:mr-0 text-center px-24 py-8 rounded-lg relative text-gray-900 bg-white flex flex-col shadow-raised`}
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col leading-relaxed py-8 -mx-8 bg-gray-100 rounded-t-lg`}
  .name {
    ${tw`font-bold text-2xl`}
  }
  .price {
    ${tw`font-bold text-5xl sm:text-6xl my-1`}
  }
  .slash {
    ${tw`text-xl text-gray-500`}
  }
  .duration {
    ${tw`lowercase text-gray-500 font-medium tracking-widest`}
  }
  .mainFeature {
    ${tw`text-gray-500 text-sm font-medium tracking-wide`}
  }
`;

const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 flex-1 text-sm`}
  .feature {
    ${tw`mt-5 first:mt-0 font-semibold text-gray-500`}
  }
`;

const PlanAction = tw.div`px-4 pb-8`;
const ContactUsButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
`;

const AddonOptionsContainer = tw.div`w-full max-w-md mt-8 text-left px-8`;
const AddonTitle = tw.h5`mt-8 text-lg font-bold text-gray-700`;
const AddonOptionBox = styled.div`
  ${tw`mt-4 flex justify-between items-center cursor-pointer p-4 border rounded-lg transition-all duration-300`}
  ${props => props.active ? tw`bg-primary-500 text-white` : tw`bg-white text-gray-900 hover:bg-gray-100`}
  .name {
    ${tw`font-semibold`}
  }
  .price {
    ${tw`text-primary-500 font-bold`}
    ${props => props.active && tw`text-white`}
  }
`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-2/3 -translate-y-1/2`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-25 transform translate-x-2/3 translate-y-1/2 fill-current text-teal-300`}
`;

const Select = styled.select`
  ${tw`mt-4 px-4 py-2 border rounded text-lg`}
`;

const SelectionTitle = tw.h5`mt-6 text-lg font-bold text-gray-700`;

export default ({
  subheading = "Pricing",
  heading = "Flexible Plans.",
  description = "Choose between different rack sizes and power consumption options.",
  primaryButtonText = "Contact Us"
}) => {
  const [selectedBaie, setSelectedBaie] = useState("1/4 Rack");
  const [selectedConsumption, setSelectedConsumption] = useState("1kW");
  const [price, setPrice] = useState(100);

  const [addons, setAddons] = useState({
    antiDDoS: false,
    ipFailover: false,
    prioritySupport: false,
    backup: false,
    monitoring: false,
  });

  const addonPrices = {
    antiDDoS: 10,
    ipFailover: 5,
    prioritySupport: 15,
    backup: 8,
    monitoring: 12,
  };

  const calculatePrice = (baie, consumption, addons) => {
    let basePrice;
    switch (baie) {
      case "1/4 Rack":
        basePrice = 100;
        break;
      case "1/2 Rack":
        basePrice = 200;
        break;
      case "Full Rack":
        basePrice = 400;
        break;
      default:
        basePrice = 100;
    }

    let consumptionMultiplier;
    switch (consumption) {
      case "1kW":
        consumptionMultiplier = 1;
        break;
      case "2kW":
        consumptionMultiplier = 1.5;
        break;
      case "3kW":
        consumptionMultiplier = 2;
        break;
      default:
        consumptionMultiplier = 1;
    }

    let totalAddonsPrice = 0;
    Object.keys(addons).forEach((addon) => {
      if (addons[addon]) {
        totalAddonsPrice += addonPrices[addon];
      }
    });

    return basePrice * consumptionMultiplier + totalAddonsPrice;
  };

  const handleBaieChange = (e) => {
    const newBaie = e.target.value;
    setSelectedBaie(newBaie);
    setPrice(calculatePrice(newBaie, selectedConsumption, addons));
  };

  const handleConsumptionChange = (e) => {
    const newConsumption = e.target.value;
    setSelectedConsumption(newConsumption);
    setPrice(calculatePrice(selectedBaie, newConsumption, addons));
  };

  const toggleAddon = (addon) => {
    setAddons((prevAddons) => ({
      ...prevAddons,
      [addon]: !prevAddons[addon],
    }));
    setPrice(calculatePrice(selectedBaie, selectedConsumption, { ...addons, [addon]: !addons[addon] }));
  };

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeaderContainer>

        <PlansContainer>
          <Plan>
            <PlanHeader>
              <span className="priceAndDuration">
                <span className="price">€{price}</span>
                <span className="slash"> / </span>
                <span className="duration">month</span>
              </span>
              <span className="name">{selectedBaie}</span>
              <span className="mainFeature">{selectedConsumption} Power Consumption</span>
            </PlanHeader>

            <PlanFeatures>
              <SelectionTitle>Select Your Rack Size</SelectionTitle>
              <Select value={selectedBaie} onChange={handleBaieChange}>
                <option value="1/4 Rack">1/4 Rack</option>
                <option value="1/2 Rack">1/2 Rack</option>
                <option value="Full Rack">Full Rack</option>
              </Select>

              <SelectionTitle>Select Your Power Consumption</SelectionTitle>
              <Select value={selectedConsumption} onChange={handleConsumptionChange}>
                <option value="1kW">1 kW</option>
                <option value="2kW">2 kW</option>
                <option value="3kW">3 kW</option>
              </Select>
            </PlanFeatures>

            <PlanAction>
              <ContactUsButton>{primaryButtonText}</ContactUsButton>
            </PlanAction>
          </Plan>

          {/* Add-on options with clickable boxes */}
          <AddonOptionsContainer>
            <AddonTitle>Additional Options</AddonTitle>

            <AddonOptionBox
              active={addons.antiDDoS}
              onClick={() => toggleAddon("antiDDoS")}
            >
              <span className="name">AntiDDoS</span>
              <span className="price">+ €10 / month</span>
            </AddonOptionBox>

            <AddonOptionBox
              active={addons.ipFailover}
              onClick={() => toggleAddon("ipFailover")}
            >
              <span className="name">IP Failover</span>
              <span className="price">+ €5 / month</span>
            </AddonOptionBox>

            <AddonOptionBox
              active={addons.prioritySupport}
              onClick={() => toggleAddon("prioritySupport")}
            >
              <span className="name">Priority Support</span>
              <span className="price">+ €15 / month</span>
            </AddonOptionBox>

            <AddonOptionBox
              active={addons.backup}
              onClick={() => toggleAddon("backup")}
            >
              <span className="name">Automatic Backup</span>
              <span className="price">+ €8 / month</span>
            </AddonOptionBox>

            <AddonOptionBox
              active={addons.monitoring}
              onClick={() => toggleAddon("monitoring")}
            >
              <span className="name">Advanced Monitoring</span>
              <span className="price">+ €12 / month</span>
            </AddonOptionBox>
          </AddonOptionsContainer>
        </PlansContainer>
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
